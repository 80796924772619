import { useState } from "react";
import Pay from "../Common/cryptoPay";
import { toast } from "react-toastify";
import { ApiHook } from "../../hooks/apiHook";
import LoadingOverlay from "../Common/LoadingOverlay";

const UnilevelPayment = ({ props }) => {
  console.log(props, "props unilevel payment");
  let paymentCheckData = {};
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const metaPayment = ApiHook.CallMetaPaymentForUnilevel();
  const unilevelPayment = ApiHook.MakeUnilevelPayment();
  const handleSubmit = () => {
    unilevelPayment.mutateAsync(paymentCheckData);
  };

  return (
    <div>
      {/* <h1>Unilevel Payment</h1> */}
      <Pay
        title="Pay Subscription Fee"
        amount={props?.isUnilevelActive?.amount ?? 0}
        toAddress={props?.isUnilevelActive?.address ?? ""}
        oninitiate={(data) => {
          console.log(data, "data in oninitiate");
          setMetaMaskLoading(true);
          if (data.status) {
            const resultData = {
              hash: data.hash,
              amount: props?.isUnilevelActive?.amount ?? 0,
              toAddress: props?.isUnilevelActive?.address ?? "",
            };
            metaPayment.mutateAsync(resultData);
          }
        }}
        onsubmit={(result) => {
          console.log(result, "result in onSubmit");
          if (result.status) {
            paymentCheckData = {
              hash: result.data.transactionHash,
              amount: props?.isUnilevelActive?.amount ?? 0,
              toAddress: props?.isUnilevelActive?.address ?? "",
            };
            if (result.status) {
              console.log("success", result);
              toast.success("Payment Completed. Please wait.");
              handleSubmit();
            }
          } else {
            setMetaMaskLoading(false);
            console.log("error", result);
            toast.error(result.message);
          }
        }}
        metaMaskLoading={metaMaskLoading}
        disabled={metaMaskLoading}
      />
      <LoadingOverlay isLoading={metaMaskLoading === true ? true : false} />
    </div>
  );
};

export default UnilevelPayment;
